import React, { useState } from "react";
import SubmitButton from "../buttons/SubmitButton";
import { useTranslation } from "react-i18next";
import { DatePicker } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;

const DateRangePickerFilter = ({
    setDateRange,
    setCurrentPage,
}) => {
    const { t } = useTranslation();
    const [dateKey, setDateKey] = useState(Date.now());
    const [date, setDate] = useState({ startDate: moment().startOf('M').format("YYYY-MM-DD"), endDate: moment().endOf('M').format("YYYY-MM-DD") });

    // Adding lang to the category
    const handleTabChange = (e) => {
        setDateRange({
            startDate: date.startDate,
            endDate: date.endDate
        });
        if (e.target.id === "searchButton") {
            setCurrentPage(1);

        } else if (e.target.id === "resetButton") {

            setDateRange({ startDate: moment().startOf('M').format("YYYY-MM-DD"), endDate: moment().endOf('M').format("YYYY-MM-DD") });
            setDate({ startDate: moment().startOf('M').format("YYYY-MM-DD"), endDate: moment().endOf('M').format("YYYY-MM-DD") });
            setDateKey(Date.now());
        }
    };
    const handleDateRange = (dates) => {
        if (Array.isArray(dates) && dates.length === 2) {
            const [toDate, fromDate] = dates;
            setDate({
                startDate: toDate.format("YYYY-MM-DD"),
                endDate: fromDate.format("YYYY-MM-DD")
            });
        }
    };
    return (
        <div className="filter_Section">
            <div className="row">
                <div key={"1"} className="col-xl-2 col-md-3">
                    <div className="right_search_div">
                        <label className="la-control">{t("date")}</label>
                        <RangePicker key={dateKey} onChange={(dates) => handleDateRange(dates)} />
                    </div>
                </div>
                <div className="col-xl-2 col-md-3 mt-4">
                    <div className="right_search_div d-flex gap-1">
                        <SubmitButton
                            isSubmitting=""
                            text="search"
                            className="btn btn-primary"
                            click={handleTabChange}
                            id="searchButton"
                        />
                        <SubmitButton
                            isSubmitting=""
                            text="reset"
                            className="btn btn-secondary"
                            click={handleTabChange}
                            id="resetButton"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DateRangePickerFilter;