import React, { useState } from 'react'
import TableMain from "../../components/Common/table/TableMain";
import { useTranslation } from "react-i18next";
import { ApiHook } from '../../hooks/apiHook';

export default function TopAcheivers() {

    const { t } = useTranslation();
    const headers = ["No.", "username", "total"]

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const Report = ApiHook.CallTopAcheivers();
    return (
        <>
            <div className="page_head_top">{t("top-acheivers")}</div>
            <div className="ewallet_table_section">
                <div className="table-responsive min-hieght-table">
                    <TableMain
                        headers={headers}
                        data={Report?.data?.data?.data}
                        startPage={1}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        type={"top-acheivers-report"}
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                    />
                </div>
            </div>
        </>
    )
}
