import { reportService } from "../../services/report/report";


export const GetSignupReport = async (data) => {
    try {
        const response = await reportService.signupReport(data);
        return response;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
};
export const GetRankReport = async (data) => {
    try {
        const response = await reportService.rankReport(data);
        return response;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
};
export const GetTopAcheivers = async () => {
    try {
        const response = await reportService.topAcheivers();
        return response;
    } catch (error) {
        console.log(error.message);
        return error.message;
    }
};

