import React, { useState } from 'react'
import TableMain from "../../components/Common/table/TableMain";
import { useTranslation } from "react-i18next";
import { getLastPage } from '../../utils/getLastPage';
import { ApiHook } from '../../hooks/apiHook';
import SubmitButton from "../../components/Common/buttons/SubmitButton";
import Select from "react-select";

export default function RankReport() {

    const { t } = useTranslation();
    const headers = ["No.", "username", "rank"]

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedValue, setSelectedValue] = useState("");
    const [selectedRank, setSelectedRank] = useState("");
    const handleReset = () => {
        setSelectedValue("")
        setSelectedRank("")
    };
    const handleSearch = (e) => {
        if (e.target.id === "searchButton") {
            const { value } = selectedValue
            setCurrentPage(1);
            setSelectedRank(value)
        } else if (e.target.id === "resetButton") {
            setSelectedValue("")
            setSelectedRank("")
        }
    };
    const handleSelectChange = (value) => {
        setSelectedValue(value);
    };

    const Report = ApiHook.CallRankReport(
        {
            rank: selectedRank,
            page: currentPage,
            pageSize: itemsPerPage
        }
    );
    const lastPage = getLastPage(itemsPerPage, Report?.data?.data?.data?.totalCount);
    return (
        <>
            <div className="page_head_top">{t("rank-report")}</div>
            <div className="ewallet_table_section">
                <div className="table-responsive min-hieght-table">
                    <div className="filter_Section">
                        <div className="row">
                            <div key={"2"} className="col-xl-2 col-md-3">
                                <div className="right_search_div">
                                    <label className="la-control">{t("rank")}</label>
                                    <Select
                                        options={Report?.data?.data?.data?.rankList}
                                        value={selectedValue}
                                        onChange={handleSelectChange}
                                    />
                                </div>
                            </div>
                            <div className="col-xl-2 col-md-3 mt-4">
                                <div className="right_search_div d-flex gap-1">
                                    <SubmitButton
                                        isSubmitting=""
                                        text="search"
                                        className="btn btn-primary"
                                        click={handleSearch}
                                        id="searchButton"
                                    />
                                    <SubmitButton
                                        isSubmitting=""
                                        text="reset"
                                        className="btn btn-secondary"
                                        click={handleReset}
                                        id="resetButton"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <TableMain
                        headers={headers}
                        data={Report?.data?.data?.data?.data}
                        startPage={1}
                        currentPage={currentPage}
                        totalPages={lastPage}
                        setCurrentPage={setCurrentPage}
                        type={"rank-report"}
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                    />
                </div>
            </div>
        </>
    )
}
