import React, { useState } from 'react'
import TableMain from "../../components/Common/table/TableMain";
import DateRangePickerFilter from "../../components/Common/table/DateRangePickerFilter";
import { useTranslation } from "react-i18next";
import { getLastPage } from '../../utils/getLastPage';
import moment from 'moment';
import { ApiHook } from '../../hooks/apiHook';

export default function SignupReport() {

    const { t } = useTranslation();
    const headers = ["No.", "username", "position", "placement", "join_date"]

    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [dateRange, setDateRange] = useState({ startDate: moment().startOf('M').format("YYYY-MM-DD"), endDate: moment().endOf('M').format("YYYY-MM-DD") });

    const signupReport = ApiHook.CallSignupReport(
        {
            startDate: dateRange.startDate,
            endDate: dateRange.endDate,
            page: currentPage,
            pageSize: itemsPerPage
        }
    );

    const lastPage = getLastPage(itemsPerPage, signupReport?.data?.data?.data?.totalCount);
    return (
        <>
            <div className="page_head_top">{t("signup_report")}</div>
            <div className="ewallet_table_section">
                <div className="table-responsive min-hieght-table">
                    <DateRangePickerFilter setDateRange={setDateRange} setCurrentPage={setCurrentPage} />
                    <div className='date-range'>
                        {`${moment(dateRange.startDate).format("DD-MM-YYYY")} to ${moment(dateRange.endDate).format("DD-MM-YYYY")}`}
                    </div>
                    <TableMain
                        headers={headers}
                        data={signupReport?.data?.data?.data?.data}
                        startPage={1}
                        currentPage={currentPage}
                        totalPages={lastPage}
                        setCurrentPage={setCurrentPage}
                        type={"signup-report"}
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                    />
                </div>
            </div>
        </>
    )
}
