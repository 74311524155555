import API from "../../api/api";

export const reportService = {
    signupReport: async ({ startDate, endDate, page, pageSize }) => {
        const response = API.get(`signup-report?startDate=${startDate}&endDate=${endDate}&page=${page}&pageSize=${pageSize}`);
        return response;
    },
    rankReport: async ({ rank, page, pageSize }) => {
        const response = API.get(`rank-report?rank=${rank}&page=${page}&pageSize=${pageSize}`);
        return response;
    },
    topAcheivers:async () => {
        const response = API.get(`top-acheivers`);
        return response;
    },
};
